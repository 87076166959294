export const aboutData = {
    title:`About Me`,
  comp_name: `Enis Engineering Pvt. Ltd.`,
  comp_detail: `is a Engineering, Procurement & Construction (EPC), 
    offering multidisciplinary services in the area of Engineering Construction 
    and Management having it’s registered office at New Ashok Nagar, 
    New Delhi, India.The company established in the year of 2023 & 
    the successful journey have been done under the dynamic 
    leadership of `,
  comp_owner_name: `Mr. Er. MD Intekhab Alam.`,
};
