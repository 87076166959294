import * as React from 'react';
import Banner from '../Banner';
import About from './About';
import { Grid } from '@mui/material';

function Home() {
	return (
		<React.Fragment>
			<Banner />
			<Grid container justifyContent={'center'} marginTop={4} marginBottom={3}>
				<Grid item xs={12} md={10}>
					<About />
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
export default Home;