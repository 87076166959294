import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid , } from '@mui/material';


const Banner = () => {

	return (
		<Box sx={{ position: 'relative', width: '100%', }} >
			<img
				src={'./image/banner.jpg'}
				alt="Alt Data"
				style={{ width: '100%', height: 'auto', }}
			/>
			<Grid container sx={{ position: 'absolute', color: 'white', top: '50%', left: '30px' }}>
				<Grid item xs={4}>
					<Typography variant="h6">
						Welcome to our website!
					</Typography>
				</Grid>
			</Grid>

		</Box>
	);
}

export default Banner;