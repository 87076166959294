import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { aboutData } from "../Constant/about";

const About = () => {
  const { comp_name, comp_detail, comp_owner_name ,title } = aboutData;
  return (
    <Card>
      <Typography variant="h4" sx={{ textAlign: "center", margin: 2 }}>
        { title }
      </Typography>
      <Grid container p={2}>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            image="./image/about.png"
            alt="contruction"
          />
        </Grid>
        <Grid item xs={12} md={6} marginTop={3}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h6">
              <strong>{comp_name}</strong>
              {comp_detail}
              <strong>{comp_owner_name}</strong>
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default About;
