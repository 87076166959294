import { Fragment } from 'react';
import { Header, Footer } from '../components/Shared';

const MainLayout = ({children}:any) => {
    return(
        <Fragment>
            <Header />
            { children }
            <Footer />
        </Fragment>
    )
}

export default MainLayout;